import http from "./http";
//获取剧目列表
export function getVideoList(data) {
  return http.request({
    url:'admin/list/video',
    method:'get',
    data
  })
}
//获取小程序列表
export function getMiniPragramList(data){
    return http.request({
        url:'admin/list/small/app',
        method:'get',
        data
    })
}

//获取充值模板列表
export function getRechargeTempleteList(data){
    return http.request({
        url:'admin/list/recharge/template?self=true',
        method:'get',
        data
    })
}
//获取回传列表
export function getRollBackList(data){
    return http.request({
        url:'admin/list/backhaul/rule?self=true',
        method:'get',
        data
    })
}
//获取组织账户
export function getOrganization(data){
    return http.request({
        url:'admin/list/organization',
        method:'get',
        data
    })
}

//创建广告链接
export function createAdvLink(data){
    return http.request({
        url:'/admin/promotion/link',
        method:'post',
        data
    })
}

// 获取广告链接列表
export function getAdvLinkList(data){
    return http.request({
        url:'/admin/promotion/link',
        method:'get',
        data
    })
}
//修改广告链接
export function updateAdvLink(data,id){
    console.log(data)
    return http.request({
        url:`/admin/promotion/link/${id}`,
        method:'put',
        data
    })
}
//广告链接详情
export function getAdvLinkDetail(id){
    return http.request({
        url:`/admin/promotion/link/${id}`,
        method:'get'
    })
}
//充值方案拖拽排序
export function updateRechargeTempleteSort(data){
    return http.request({
        url:'admin/recharge/options/sort/update',
        method:'put',
        data
    })
}

//获取会员类型下拉列表
export function getUserTypeList(){
    return http.request({
        url:'/admin/list/common/vip-type',
        method:'get'
    })
}
//获取充值类型下拉列表
export function getChrageTypeList(){
    return http.request({
        url:'/admin/list/common/recharge-type?self=true',
        method:'get'
    })
}
//获取充值方式类型下拉列表
export function getChargeWayTypeList(){
    return http.request({
        url:'/admin/list/common/recharge-way',
        method:'get'
    })
}
//广告链接下拉列表
export function getAdvLinkSelect(){
    return http.request({
        url:'admin/list/promotion/link?self=true',
        method:'get'
    })
}
//投放人下拉
export function getAdminSelectList(){
    return http.request({
        url:'admin/list/admin',
        method:'get'
    })
}

//部门组织管理员树状列表
export function getDepartmentTree(){
    return http.request({
        url:'/admin/department/admin/tree',
        method:'get'
    })
}
//获取表单项显示配置
export function getFormConfig(type){
    return http.request({
        url:`/admin/list/field/${type}`,
        method:'get'
    })
}

//保存表单项配置
export function saveFormConfig(data){
    return http.request({
        url:`/admin/list/field`,
        method:'post',
        data
    })
}
//校验广告id唯一性
export function checkAdvId(data){
    return http.request({
        url:`/admin/verify/organization/item/exists`,
        method:'get',
        data
    })
}
//添加广告链接获取广告平台单选框列表
export function getPlayMode(){
    return http.request({
        url:'/admin/list/common/play-mode',
        method:'get'
    })
}
//添加广告链接获取回传类型单选框列表
export function getBackhaulIaaType(){
    return http.request({
        url:'/admin/list/common/backhaul-iaa-type',
        method:'get'
    })
}