import React, { memo } from "react";
import { NoFoundWrapperd } from "./style";
import { Button } from "antd";
import errorimg from "@/static/404.png";
import { useNavigate } from "react-router-dom";

const Nofound = memo(() => {
  const navigate = useNavigate();
  return (
    <NoFoundWrapperd>
      <div className="main">
        <div className="left">
          <img src={errorimg} alt="404" className="img" />
        </div>
        <div className="right">
          <h1>404错误!</h1>
          <h3>找不到资源</h3>
          <p>
            对不起，您正在寻找的页面不存在。尝试检查URL的错误，然后按浏览器上的刷新按钮或尝试在我们的应用程序中找到其他内容。
          </p>
          <Button
            type="primary"
            style={{ width: "100px" }}
            onClick={() => {
              navigate("/");
            }}
          >
            返回首页
          </Button>
        </div>
      </div>
    </NoFoundWrapperd>
  );
});

export default Nofound;
