import React, { memo, Suspense, useEffect } from 'react';
import LayoutWrapperd from '@/layout/layout';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';
import Arrays, { blankRoutes } from './router/index';
import NoFounds from '@/pages/404/nofound';
import { transformData } from '@/router';
import { useSelector } from 'react-redux';

//权限
import { AuthProvider } from '@/context/AuthContext';

const App = memo(() => {
  const navigate = useNavigate();
  const menulist = useSelector((state) => state.homeReducer.menulist);
  const Location = useLocation();
  const loginroutes = blankRoutes.some((item) => item.path === Location.pathname);
  const routesConfig = loginroutes
    ? [...blankRoutes]
    : [...blankRoutes, ...transformData(menulist)];
  const routes = useRoutes(routesConfig);
  useEffect(() => {
    if (menulist.length === 0) {
      navigate('/login');
      return;
    }
  }, [menulist]);
  return (
    <Suspense fallback={<div>loading...</div>}>
      <React.Fragment>
        {loginroutes ? (
          routes
        ) : routes ? (
          <AuthProvider>
            <LayoutWrapperd>{routes}</LayoutWrapperd>
          </AuthProvider>
        ) : (
          <NoFounds />
        )}
      </React.Fragment>
    </Suspense>
  );
});

export default App;
