import React, { memo } from 'react'
import {LoadingWrapperd} from './styled'
import {  Spin } from 'antd'
export default memo(function loading() {
  return (
    <LoadingWrapperd>
        <Spin />
    </LoadingWrapperd>
  )
})
