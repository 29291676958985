import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMenuList } from '@/api/login';
import { getDepartmentTree } from '@/api/adv';
export const fetchMenuData = createAsyncThunk('fetch/homeData', async () => {
  const res = await getMenuList();
  return res.data;
});

export const fetchDepartmentAndPerson = createAsyncThunk('fetch/departmentAndPerson', async () => {
  const res = await getDepartmentTree();
  return res.data;
});
//处理部门数据
// const transformDeparteMentData = (data) => {
//   if (Array.isArray(data)) {
//     return data.map(transformDeparteMentData);
//   } else if (typeof data === 'object' && data !== null) {
//     let newData = {};
//     for (let key in data) {
//       if (key === 'id') {
//         newData['value'] = data[key];
//       } else if (key === 'name') {
//         newData['label'] = data[key];
//       } else if (key === 'children') {
//         if (data[key].length > 0) {
//           newData[key] = transformDeparteMentData(data[key]);
//         } else if (data['admin'].length > 0) {
//           newData[key] = transformDeparteMentData(data['admin']);
//         } else {
//           newData[key] = [];
//         }
//       } else if (key === 'admin') {
//         if (data['children'].length > 0) {
//           data[key] = transformDeparteMentData(data[key]);
//         }
//       } else {
//         newData[key] = data[key];
//       }
//     }
//     return newData;
//   }
//   return data;
// };

//处理部门数据2
const transformData = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => {
      let newItem = {};
      // 转换字段名称
      for (let key in item) {
        if (key === 'id') {
          newItem['value'] = item[key];
        } else if (key === 'name') {
          newItem['label'] = item[key];
        } else {
          newItem[key] = item[key];
        }
      }

      if (Array.isArray(newItem.admin) && newItem.admin.length > 0) {
        newItem.children = newItem.children || [];
        newItem.children.push(...newItem.admin);
        newItem.admin = [];
      }
      if (Array.isArray(newItem.children)) {
        newItem.children = transformData(newItem.children);
      }
      return newItem;
    });
  } else {
    return data;
  }
};
const homeSlice = createSlice({
  name: 'home',
  initialState: {
    menulist: JSON.parse(localStorage.getItem('menuList')) || [],
    newMenuList: [],
    //部门组织数据
    dePartmentData: [],
    newPartmentData: []
  },
  reducers: {
    changeMenuList(state, { payload }) {
      state.newMenuList = payload;
    },
    changeMenu(state, { payload }) {
      state.menulist = JSON.parse(payload);
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMenuData.fulfilled, (state, { payload }) => {
        state.newMenuList = payload;
      })
      .addCase(fetchDepartmentAndPerson.fulfilled, (state, { payload }) => {
        // const array = []
        // array.push(transformDeparteMentData(payload[0]))
        // console.log(array)
        // state.dePartmentData = array
        state.dePartmentData = transformData(payload);
      });
  }
});

export const { changeMenu, changeMenuList } = homeSlice.actions;

export default homeSlice.reducer;
