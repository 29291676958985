import styled from 'styled-components';

export const HomeWrapperd = styled.div`
  .menuall {
    height: 100%;
    position: relative;
    .menu_top {
      position: relative;
      .menu_top_logo {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        .demo-logo-vertical {
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 2px;
        }
        .logoimg {
          .logo_img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .menu_right_icon {
        width: 20px;
        height: 20px;
        position: absolute;
        right: -10px;
        z-index: 999;
        top: 17px;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 5px #ccc;
        .leftinon {
          font-size: 12px;
        }
      }
    }
    .menu_buttom {
      width: 100%;
      height: 50px;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      /* justify-content: center; */
      align-items: center;
      margin-left: 24px;
      z-index: 100;
      .userinfo {
        margin-right: 30px;
        display: flex;
        align-items: center;
        .user_img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
`;
export const LogOutWrapperd = styled.div`
  cursor: pointer;
`;
export const TabsWrapperd = styled.div`
  .ant-tabs-nav::before {
    display: none;
  }
`;
export const LoadingWrapperd = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  opacity: 0.8;
`;
