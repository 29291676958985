import React from 'react';
// import ReactDOM from 'react-dom/client';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import store from './store';
import 'reset-css';
import App from '@/App';
import 'dayjs/locale/zh-cn';
import './common/common.css';
import { AliveScope } from 'react-activation';
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <HashRouter>
//     <Provider store={store}>
//       <ConfigProvider
//         locale={zhCN}
//         theme={{
//           components: {
//             Segmented: {
//               itemSelectedColor: '#1677FF'
//             },
//             Tabs: {
//               cardPaddingSM: '2px 10px'
//             }
//           }
//         }}>
//         <App />
//       </ConfigProvider>
//     </Provider>
//   </HashRouter>
// );
ReactDOM.render(
  <HashRouter>
    <Provider store={store}>
      <AliveScope>
        <ConfigProvider
          locale={zhCN}
          theme={{
            components: {
              Segmented: {
                itemSelectedColor: '#1677FF'
              },
              Tabs: {
                cardPaddingSM: '2px 10px'
              }
            }
          }}>
          <App />
        </ConfigProvider>
      </AliveScope>
    </Provider>
  </HashRouter>,
  document.getElementById('root')
);
