import React, { createContext, useContext, useEffect, useState } from 'react';
import { getMenuList } from '@/api/login';
const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(null);
  const loadPermissions = async () => {
    const menures = await getMenuList();
    const permissionButtons = menures?.data?.filter((item) => item.type === 2)
    setPermissions(permissionButtons);
  };
  useEffect(()=>{loadPermissions()},[])
  return (
    <AuthContext.Provider value={{ permissions, loadPermissions }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {

  return useContext(AuthContext);
};

export default useAuth;