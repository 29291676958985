import http from "./http";


// 登录
export function adminLogin(data) {
  return http.request({
    method: "post",
    url: "/admin/login",
    data,
  });
}
//修改密码
export function upDatePassword(data){
  return http.request({
    method: "put",
    url: "/admin/password/update",
    data,
  });
}
//获取用户信息
export function getUserInfo(id) {
  return http.request({
    method: "get",
    url: `/admin/user/${id}`,
  });
}
//获取菜单列表
export function getMenuList(){
  return http.request({
    method: "get",
    url: `/admin/menu`,
  });
}