import axios from "axios";
import { message } from "antd";
message.config({
  maxCount: 1,
});
class HttpRequest {
  // #baseUrl
  constructor() {
    this.baseUrl = "https://api-playlet-universal.yuan-bao.com";
    this.withCredentials = false;
    this.timeout = 20000;
  }

  getConfig() {
    const token = localStorage.getItem("token");
    const config = {
      baseURL: this.baseUrl,
      timeout: this.timeout,
      withCredentials: this.withCredentials,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  }

  getParams(payload) {
    const { method, data } = payload;
    if (["post", "put", "patch", "delete"].indexOf(method) >= 0) {
      payload.data = data;
    } else {
      payload.params = data;
      delete payload.data;
    }
    return payload;
  }

  checkStatus(status) {
    let errMessage = "";
    switch (status) {
      case 400:
        errMessage = "错误请求";
        break;
      case 401:
        errMessage = "未授权，请重新登录";
        setTimeout(() => {
          window.location.hash = "/login";
          localStorage.removeItem("token");
        }, 1000);
        break;
      default:
        errMessage = `连接错误`;
    }
    return errMessage;
  }

  // 拦截处理
  setInterceptors(instance) {
    const that = this;

    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    //响应拦截
    instance.interceptors.response.use(
      (res) => {
        return res.data;
      },
      (error) => {
        message.error(error.response.data.message);
        that.checkStatus(error.response.status);
        return error.response.data;
      }
    );
  }

  request(options) {
    const instance = axios.create();
    const baseOpt = this.getConfig();
    const params = Object.assign({}, baseOpt, this.getParams(options));
    this.setInterceptors(instance);
    return instance(params);
  }
}
const quest = new HttpRequest();

export default quest;
