import styled from "styled-components";
export const NoFoundWrapperd = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .main {
    margin: auto;
    height: 328px;
    display: flex;
    .left {
      width: 600px;
      height: 100%;
      .img {
        display: block;
        width: 600px;
        height: 328px;
      }
    }
    .right {
      width: 300px;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 50px 0;
      h1 {
        font-weight: 600;
        font-size: 32px;
        color: #1482f0;
      }
      h3 {
        font-weight: 600;
        font-size: 20px;
        color: #000;
      }
      p {
        color: grey;
        font-size: 14px;
      }
    }
  }
`;
