import React, { Suspense, useEffect, useState } from 'react';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LoginOutlined,
  CreditCardOutlined,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';
import * as Icons from '@ant-design/icons';
import { Layout, Menu, theme, Popover, Space, Modal, Form, Tabs } from 'antd';
import { HomeWrapperd, LogOutWrapperd, TabsWrapperd } from './styled';
import { useNavigate, useRoutes, useLocation } from 'react-router-dom';
import Password from 'antd/lib/input/Password';
import { upDatePassword, getMenuList } from '@/api/login';
import { message } from 'antd';
import Loading from './loading';
import { changeMenu, fetchDepartmentAndPerson } from '@/store/modules/home';
import { transformData } from '@/router';
import { useSelector, useDispatch } from 'react-redux';
import logoimg from '@/static/logo.png';
import userimg from '@/static/userimg.jpg';
import KeepAlive, { useAliveController } from 'react-activation';
const { Header, Content, Sider } = Layout;
const Home = () => {
  const { drop, dropScope, clear, getCachingNodes, refreshScope } = useAliveController();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    token: { colorBgContainer }
  } = theme.useToken();
  const [collapsed, setCollapsed] = useState(false);
  //获取菜单列表
  const [menuList, setMenuList] = useState([]);
  //路由
  const [routes, setRoutes] = useState([]);
  //面包屑
  const [breadcrumb, setBreadcrumb] = useState([
    {
      label: '投放链接',
      key: '/advertising/advlink',
      closable: false
    }
  ]);
  const [activeKeyTabs, setActiveKeyTabs] = useState('/advertising/advlink');
  useEffect(() => {
    const fn = async () => {
      const menures = await getMenuList();
      if (menures?.data) {
        localStorage.setItem('menuList', JSON.stringify(menures.data));
        dispatch(fetchDepartmentAndPerson());
        dispatch(changeMenu(JSON.stringify(menures.data)));
      }
    };
    fn();
  }, []);

  const menuroutes = useSelector((state) => state.homeReducer.menulist);
  useEffect(() => {
    setMenuList(formatMenuData(menuroutes).reverse());
    setRoutes(transformData(menuroutes));
  }, [menuroutes]);
  const findTitles = (routes, pathurl) => {
    //处理path
    const pathSnippets = currentLocation.pathname.split('/').filter((i) => i);
    const path1 = `/${pathSnippets[0]}`;
    // 首先找到匹配"/advertising"的对象
    const advertisingObj = routes.find((route) => route.path === path1);

    let titles = [];
    if (advertisingObj && advertisingObj.meta && advertisingObj.meta.title) {
      const advlinkObj = advertisingObj.children.find((child) => child.path == pathurl);

      if (advlinkObj && advlinkObj.meta && advlinkObj.meta.title) {
        const breadcrumbObj = breadcrumb.find(
          (breadcrumb) => breadcrumb.label == advlinkObj.meta.title
        );
        if (breadcrumbObj) {
          titles = breadcrumb.map((item) => {
            if (item.label == advlinkObj.meta.title) {
              return {
                ...item,
                key: !!currentLocation.search
                  ? advlinkObj.path + currentLocation.search
                  : advlinkObj.path
              };
            }
            return item;
          });
        } else {
          titles = [
            ...breadcrumb,
            {
              label: advlinkObj.meta.title,
              key: !!currentLocation.search
                ? advlinkObj.path + currentLocation.search
                : advlinkObj.path
            }
          ];
        }
        setBreadcrumb(titles);
      }
    }
  };
  //历史记录标签
  const onEditTabs = (key, e) => {
    if (e == 'remove') {
      let newActiveKey = activeKeyTabs;
      let lastIndex = -1;
      breadcrumb.forEach((item, i) => {
        if (item.key == key) {
          lastIndex = i - 1;
        }
      });
      const newPanes = breadcrumb.filter((item) => item.key != key);
      if (newPanes.length && newActiveKey == key) {
        newActiveKey = newPanes[lastIndex].key;
        navigate(newActiveKey);
      }
      setBreadcrumb(newPanes);
      setActiveKeyTabs(newActiveKey);
    }
  };
  const formatMenuData = (menuData) => {
    let menuMap = {};
    menuData.forEach((menu) => {
      if (menu.type !== 2) {
        let formattedMenu = {
          ...menu,
          label: menu.name,
          key: menu.url,
          icon: menu.icon === '#' ? null : React.createElement(Icons[menu.icon])
        };

        if (!menuMap[menu.parent_id]) {
          menuMap[menu.parent_id] = [];
        }

        menuMap[menu.parent_id].push(formattedMenu);
      }
    });

    const buildSubMenu = (parentId) => {
      return (menuMap[parentId] || []).map((menu) => {
        let children = buildSubMenu(menu.id);

        if (children.length) {
          menu.children = children.reverse();
        }
        return menu;
      });
    };

    return buildSubMenu(0);
  };

  const currentLocation = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([currentLocation.pathname]);
  const getOpenKeysFromLocation = (locationPathname) => {
    const pathSnippets = currentLocation.pathname.split('/').filter((i) => i);

    // 这里我们使用路径的第一部分作为默认展开的菜单项
    if (pathSnippets.length) {
      return [`/${pathSnippets[0]}`];
    }
    return []; // 如果没有匹配的，返回空数组
  };
  const [openKeys, setOpenKeys] = useState(
    getOpenKeysFromLocation(currentLocation.pathname) || '/advertising/advlink'
  );
  // 修改 handleOpenChange 以处理展开与收起的逻辑
  const handleOpenChange = (openKeys) => {
    setOpenKeys(openKeys);
  };
  const menuClick = async (e) => {
    if (e.key == currentLocation.pathname) return;
    await refreshScope(e.key);
    navigate(e.key);
    setOpenKeys(e.key);
  };
  const [userInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 检查 Sider 折叠状态变化
  useEffect(() => {
    // 更新选中的菜单项
    setSelectedKeys([currentLocation.pathname]);
    if (collapsed) {
      setOpenKeys([]);
    } else {
      setOpenKeys(getOpenKeysFromLocation(currentLocation.pathname));
    }

    findTitles(transformData(menuroutes), currentLocation.pathname);
    setActiveKeyTabs(
      !!currentLocation.search
        ? currentLocation.pathname + currentLocation.search
        : currentLocation.pathname
    );
  }, [currentLocation, collapsed]);

  const LogoutButton = () => {
    return (
      <LogOutWrapperd>
        <Space direction="vertical">
          <div onClick={showModal}>
            <CreditCardOutlined />
            <span className="m-l-10">修改密码</span>
          </div>
          <div onClick={loginout}>
            <LoginOutlined />
            <span className="m-l-10">退出登录</span>
          </div>
        </Space>
      </LogOutWrapperd>
    );
  };
  const loginout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    navigate('/login');
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const changePassword = async () => {
    try {
      const res = await form.validateFields();
      const res2 = await upDatePassword(res);
      if (res2?.code == 200) {
        setIsModalOpen(false);
        message.success('修改成功,请重新登录');
        setTimeout(() => {
          localStorage.removeItem('token');
          navigate('/login');
        }, 1500);
      } else {
        message.error('修改失败');
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [form] = Form.useForm();
  return (
    <HomeWrapperd>
      <Modal
        title="修改密码"
        open={isModalOpen}
        onOk={changePassword}
        onCancel={() => {
          setIsModalOpen(false);
        }}>
        <Form
          form={form}
          name="info"
          autoComplete="off"
          style={{
            maxWidth: 600
          }}
          layout="vertical">
          <Form.Item
            label="原密码"
            name="old_password"
            rules={[
              {
                required: true
              }
            ]}>
            <Password />
          </Form.Item>
          <Form.Item
            label="密码"
            name="password"
            rules={[
              {
                required: true
              }
            ]}>
            <Password />
          </Form.Item>

          {/* Field */}
          <Form.Item
            label="确认密码"
            name="password_confirmation"
            dependencies={['password']}
            rules={[
              {
                required: true
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('两次输入的密码不一致'));
                }
              })
            ]}>
            <Password />
          </Form.Item>
        </Form>
      </Modal>
      <Layout>
        <Sider
          breakpoint="lg"
          width="180"
          trigger={null}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{
            background: colorBgContainer,
            height: '100vh',
            overflow: 'auto'
          }}>
          <div className="menuall">
            <div className="menu_top">
              <div className="menu_top_logo">
                <div className="logoimg">
                  <img src={logoimg} alt="logo" className="logo_img" />
                </div>
                {!collapsed && <div className="demo-logo-vertical">沅宝短剧后台</div>}
              </div>
              <div className="menu_right_icon" onClick={() => setCollapsed(!collapsed)}>
                {!collapsed && <LeftOutlined className="leftinon" />}
                {collapsed && <RightOutlined className="leftinon" />}
              </div>
              <Menu
                theme="light"
                mode="inline"
                openKeys={openKeys}
                selectedKeys={selectedKeys}
                onClick={menuClick}
                onOpenChange={handleOpenChange}
                inlineCollapsed={collapsed}
                items={menuList}
              />
            </div>
            <div className="menu_buttom">
              <Popover content={LogoutButton}>
                <div className="userinfo">
                  <img src={userimg} alt="用户头像" className="user_img" />
                  {!collapsed && (
                    <span style={{ marginLeft: '8px' }} className="my-line-1">
                      {userInfo?.name}
                    </span>
                  )}
                </div>
              </Popover>
            </div>
          </div>
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
              minHeight: '48px',
              height: '48px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column'
            }}>
            <div
              className="p-l-20"
              style={{
                width: '100%',
                height: '28px',
                padding: '0',
                background: colorBgContainer,
                marginTop: '20px'
              }}>
              <TabsWrapperd>
                <Tabs
                  type="editable-card"
                  hideAdd
                  size="small"
                  onChange={(e) => navigate(e)}
                  activeKey={activeKeyTabs}
                  onEdit={onEditTabs}
                  items={breadcrumb}
                  tabBarGutter={10}
                />
              </TabsWrapperd>
            </div>
          </Header>
          <Content style={{ width: '100%', height: 'calc(100vh - 120px)' }}>
            <Suspense fallback={<Loading />}>{useRoutes(routes)}</Suspense>
          </Content>
        </Layout>
      </Layout>
    </HomeWrapperd>
  );
};
export default Home;
