import React, { lazy } from 'react';
import * as Icons from '@ant-design/icons';
const Login = lazy(() => import('@/pages/login/Login'));
//设置
const Launch = lazy(() => import('@/pages/Setting/Launch/Launch'));
// const Miniprogram = lazy(() =>
//   import("@/pages/Setting/Miniprogram/Miniprogram")
// );
const PostManage = lazy(() => import('@/pages/Setting/PostManage/PostManage'));
const RoleSetting = lazy(() => import('@/pages/Setting/RoleSetting/RoleSetting'));
const DepartmentManage = lazy(() => import('@/pages/Setting/DepartmentManage/DepartmentManage'));
const AuditManage = lazy(() => import('@/pages/Setting/AuditManage'));
//剧目
// const Recommend = lazy(() => import("@/pages/Movies/Recommend/Recommend"));
const VideoPlayback = lazy(() => import('@/pages/Movies/VideoPlayback/VideoPlayback'));
// const DashBoard = lazy(() => import('@/pages/dashboard/dashboard'));
const Setting = lazy(() => import('@/pages/Setting/index'));

//广告回传
const Advertising = lazy(() => import('@/pages/advertising/index'));
const AdvLink = lazy(() => import('@/pages/advertising/advLink/advLink'));
const AdvPassback = lazy(() => import('@/pages/advertising/advPassback/advPassback'));
// const AddPayConfig = lazy(() =>
//   import("@/pages/advertising/addPayConfig/config")
// );
const AddConfig = lazy(() => import('@/pages/advertising/addConfig/add'));
const EditConfig = lazy(() => import('@/pages/advertising/editConfig/editConfig'));
const Charge = lazy(() => import('@/pages/advertising/charge/charge'));

//数据与绩效
const Performance = lazy(() => import('@/pages/Performance/index'));
const Advlinkresult = lazy(() => import('@/pages/Performance/Advlinkresult/index'));
const EpisodeRake = lazy(() => import('@/pages/Performance/EpisodeRake/index'));
const UserOrder = lazy(() => import('@/pages/Performance/UserOrder/index'));
const UserInfo = lazy(() => import('@/pages/Performance/UserInfo/index'));
const Demo = lazy(() => import('@/pages/Performance/Demo/index'));
const Iaa = lazy(() => import('@/pages/Performance/Iaa/Iaa'));
const SingularDetail = lazy(() => import('@/pages/Performance/SingularDetail/SingularDetail'));
const Material = lazy(() => import('@/pages/Performance/Material/index'));
//影剧
const Movies = lazy(() => import('@/pages/Movies/index'));
const Shortmovie = lazy(() => import('@/pages/Movies/Shortmovie/index'));
//用户管理
const User = lazy(() => import('@/pages/user/index'));
const UserAdmin = lazy(() => import('@/pages/user/userAdmin/userAdmin'));

//cps管理
const CpsSetting = lazy(() => import('@/pages/Setting/CpsSetting/index'));

const components = {
  '/advertising': Advertising,
  '/advertising/advlink': AdvLink,
  '/advertising/passback': AdvPassback,
  '/advertising/charge': Charge,
  '/advertising/addConfig': AddConfig,
  '/advertising/editConfig': EditConfig,
  '/performance': Performance,
  '/performance/advlink': Advlinkresult,
  '/performance/episoderake': EpisodeRake,
  '/performance/userorder': UserOrder,
  '/performance/userinfo': UserInfo,
  '/performance/iaa': Iaa,
  '/performance/demo': Demo,
  '/performance/material':Material,
  '/movies': Movies,
  '/movies/shortmovie': Shortmovie,
  '/movies/videoplayback': VideoPlayback,
  '/user': User,
  '/user/userAdmin': UserAdmin,
  '/setting': Setting,
  '/setting/launch': Launch,
  '/setting/hostManage': PostManage,
  '/setting/departmentManage': DepartmentManage,
  '/setting/roleSetting': RoleSetting,
  '/setting/cpsSetting': CpsSetting,
  '/setting/auditManage': AuditManage,
};

//处理菜单
export const transformData = (data) => {
  try {
    let map = {};
    let nodeData = [];
    // 首先，将数据存入哈希映射中，以便在构建树时获取
    data?.forEach((item) => {
      if (item.type === 0 || item.type === 1) {
        map[item.id] = {
          path: item.url,
          meta: {
            title: item.name,
            icon: item.icon === '#' ? null : React.createElement(Icons[item.icon])
          },
          children: [],
          element: React.createElement(components[item.url])
        };
      }
      if (item.url === '/advertising') {
        map[item.id].children.push(
          {
            path: '/advertising/addConfig',
            element: <AddConfig />,
            children: [],
            meta: {
              title: '添加方案'
            }
          },
          {
            path: '/advertising/editConfig/:id',
            element: <EditConfig />,
            children: [],
            meta: {
              title: '编辑方案'
            }
          }
        );
      }
      if (item.url === '/movies') {
        map[item.id].children.push({
          path: '/movies/videoplayback',
          element: <VideoPlayback />,
          children: [],
          meta: {
            title: '剧集播放'
          }
        });
      }
      if (item.url === '/performance') {
        map[item.id].children.push(
          {
            path: '/performance/userinfo',
            element: <UserInfo />,
            children: [],
            meta: {
              title: '用户信息'
            }
          },
          {
            path: '/performance/singulardetail/:id',
            element: <SingularDetail />,
            children: [],
            meta: {
              title: '单剧详情'
            }
          },
          {
            path: '/performance/demo',
            element: <Demo />,
            children: [],
            meta: {
              title: 'Demo'
            }
          }
        );
      }
      if (item.url === '/setting') {
        map[item.id].children.push(
          {
            path: '/setting/auditManage',
            element: <AuditManage />,
            children: [],
            meta: {
              title: '短剧审核与管理'
            }
          }
          // {
          //   path: '/setting/addAudit',
          //   element: <AddAudit />,
          //   children: [],
          //   meta: {
          //     title: '新增提审'
          //   }
          // }
        );
      }
    });
    // 然后，构建树
    data?.forEach((item) => {
      if (item.type === 0 || item.type === 1) {
        let node = map[item.id];
        if (item.parent_id === 0) {
          // 如果没有父节点，那么它就是一个根节点
          nodeData.push(node);
        } else {
          // 如果有父节点，那么把自己添加到父节点的children数组中
          map[item.parent_id]?.children.push(node);
        }
      }
    });
    // 删掉每个节点children数组中的空的children
    deleteEmptyChildren(nodeData);
    return nodeData.reverse();
  } catch (error) {}
};

const deleteEmptyChildren = (data) => {
  data.forEach((item) => {
    if (item.children.length === 0) delete item.children;
    else deleteEmptyChildren(item.children);
  });
};
const basicRoutes = [];
export const blankRoutes = [
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/advertising',
    element: <Advertising />,
    meta: {
      title: '广告投放',
      icon: ''
    },
    children: [
      {
        path: '/advertising/advlink',
        element: <AdvLink />,
        meta: {
          title: '投放链接',
          icon: ''
        }
      }
    ]
  }
];
export const errorRoutes = [
  {
    path: '*',
    element: <div>NOT FOUND</div>
  }
];
const getPathList = (routes, rootPath = '') => {
  const pathList = [];
  routes.forEach((route) => {
    const fullPath = rootPath + route.path;
    pathList.push(fullPath);

    if (route.children) {
      pathList.push(...getPathList(route.children, fullPath + '/'));
    }
  });
  return pathList;
};
const Arrays = [...basicRoutes];
export default Arrays;
// 空白布局
export const blankPages = getPathList(blankRoutes);
